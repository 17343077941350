<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
        <!--begin: Wizard Nav-->
        <div class="wizard-nav border-bottom">
          <div class="wizard-steps p-8 p-lg-10">
            <div class="wizard-step" data-wizard-type="step" data-wizard-state="current">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-bus-stop"></i>
                <h3 class="wizard-title">1. Nhập kỳ</h3>
              </div>
              <i class="wizard-arrow flaticon2-next"></i>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-list"></i>
                <h3 class="wizard-title">2. Nhập vé</h3>
              </div>
              <!-- <i class="wizard-arrow flaticon2-next"></i> -->
            </div>
            <!-- <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-responsive"></i>
                <h3 class="wizard-title">3. Hoàn tất</h3>
              </div>
              <i class="wizard-arrow flaticon2-next"></i>
            </div> -->
            <!-- <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-truck"></i>
                <h3 class="wizard-title">4. Delivery Address</h3>
              </div>
              <i class="wizard-arrow flaticon2-next"></i>
            </div> -->
            <!-- <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-globe"></i>
                <h3 class="wizard-title">3. Hoàn tất</h3>
              </div>
              <i class="wizard-arrow last flaticon2-next"></i>
            </div> -->
          </div>
        </div>
        <!--end: Wizard Nav-->

        <!--begin: Wizard Body-->
        <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
          <div class="col-xl-12 col-xxl-10">
            <!--begin: Wizard Form-->
            <form class="form" id="kt_form">
              <!--begin: Wizard Step 1-->
              <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                <h3 class="mb-10 font-weight-bold text-dark">Nhập thông tin lô vé</h3>
                <div class="form-group">
                  <label>Ngày xổ</label>
                  <datePicker
                    v-model="periodData.lottery_time"
                    :config="dpOptions"
                    @dp-change="getPeriodChannels"
                  ></datePicker>
                </div>
                <div class="form-group">
                  <label>Đài</label>
                  <b-form-radio-group
                    id="period_channel"
                    v-model="periodData.channel"
                    :options="currentPeriodChannels"
                    name="period-channels"
                    size="lg"
                    required
                  ></b-form-radio-group>
                </div>
                <!-- <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Postcode</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="postcode"
                        placeholder="Postcode"
                        value="3000"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Postcode.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>City</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="city"
                        placeholder="City"
                        value="Melbourne"
                      />
                      <span class="form-text text-muted"
                        >Please enter your City.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>State</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="state"
                        placeholder="State"
                        value="VIC"
                      />
                      <span class="form-text text-muted"
                        >Please enter your State.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Country</label>
                      <select
                        name="country"
                        class="form-control form-control-solid form-control-lg"
                      >
                        <option value="">Select</option>
                        <option value="AF">Afghanistan</option>
                        <option value="AX">Åland Islands</option>
                        <option value="AL">Albania</option>
                        <option value="DZ">Algeria</option>
                        <option value="AS">American Samoa</option>
                        <option value="AD">Andorra</option>
                        <option value="AO">Angola</option>
                        <option value="AI">Anguilla</option>
                        <option value="AQ">Antarctica</option>
                        <option value="AG">Antigua and Barbuda</option>
                        <option value="AR">Argentina</option>
                        <option value="AM">Armenia</option>
                        <option value="AW">Aruba</option>
                        <option value="AU" selected>Australia</option>
                        <option value="AT">Austria</option>
                        <option value="AZ">Azerbaijan</option>
                        <option value="BS">Bahamas</option>
                        <option value="BH">Bahrain</option>
                        <option value="BD">Bangladesh</option>
                        <option value="BB">Barbados</option>
                        <option value="BY">Belarus</option>
                        <option value="BE">Belgium</option>
                        <option value="BZ">Belize</option>
                        <option value="BJ">Benin</option>
                        <option value="BM">Bermuda</option>
                        <option value="BT">Bhutan</option>
                        <option value="BO"
                          >Bolivia, Plurinational State of</option
                        >
                        <option value="BQ"
                          >Bonaire, Sint Eustatius and Saba</option
                        >
                        <option value="BA">Bosnia and Herzegovina</option>
                        <option value="BW">Botswana</option>
                        <option value="BV">Bouvet Island</option>
                        <option value="BR">Brazil</option>
                        <option value="IO"
                          >British Indian Ocean Territory</option
                        >
                        <option value="BN">Brunei Darussalam</option>
                        <option value="BG">Bulgaria</option>
                        <option value="BF">Burkina Faso</option>
                        <option value="BI">Burundi</option>
                        <option value="KH">Cambodia</option>
                        <option value="CM">Cameroon</option>
                        <option value="CA">Canada</option>
                        <option value="CV">Cape Verde</option>
                        <option value="KY">Cayman Islands</option>
                        <option value="CF">Central African Republic</option>
                        <option value="TD">Chad</option>
                        <option value="CL">Chile</option>
                        <option value="CN">China</option>
                        <option value="CX">Christmas Island</option>
                        <option value="CC">Cocos (Keeling) Islands</option>
                        <option value="CO">Colombia</option>
                        <option value="KM">Comoros</option>
                        <option value="CG">Congo</option>
                        <option value="CD"
                          >Congo, the Democratic Republic of the</option
                        >
                        <option value="CK">Cook Islands</option>
                        <option value="CR">Costa Rica</option>
                        <option value="CI">Côte d'Ivoire</option>
                        <option value="HR">Croatia</option>
                        <option value="CU">Cuba</option>
                        <option value="CW">Curaçao</option>
                        <option value="CY">Cyprus</option>
                        <option value="CZ">Czech Republic</option>
                        <option value="DK">Denmark</option>
                        <option value="DJ">Djibouti</option>
                        <option value="DM">Dominica</option>
                        <option value="DO">Dominican Republic</option>
                        <option value="EC">Ecuador</option>
                        <option value="EG">Egypt</option>
                        <option value="SV">El Salvador</option>
                        <option value="GQ">Equatorial Guinea</option>
                        <option value="ER">Eritrea</option>
                        <option value="EE">Estonia</option>
                        <option value="ET">Ethiopia</option>
                        <option value="FK">Falkland Islands (Malvinas)</option>
                        <option value="FO">Faroe Islands</option>
                        <option value="FJ">Fiji</option>
                        <option value="FI">Finland</option>
                        <option value="FR">France</option>
                        <option value="GF">French Guiana</option>
                        <option value="PF">French Polynesia</option>
                        <option value="TF">French Southern Territories</option>
                        <option value="GA">Gabon</option>
                        <option value="GM">Gambia</option>
                        <option value="GE">Georgia</option>
                        <option value="DE">Germany</option>
                        <option value="GH">Ghana</option>
                        <option value="GI">Gibraltar</option>
                        <option value="GR">Greece</option>
                        <option value="GL">Greenland</option>
                        <option value="GD">Grenada</option>
                        <option value="GP">Guadeloupe</option>
                        <option value="GU">Guam</option>
                        <option value="GT">Guatemala</option>
                        <option value="GG">Guernsey</option>
                        <option value="GN">Guinea</option>
                        <option value="GW">Guinea-Bissau</option>
                        <option value="GY">Guyana</option>
                        <option value="HT">Haiti</option>
                        <option value="HM"
                          >Heard Island and McDonald Islands</option
                        >
                        <option value="VA"
                          >Holy See (Vatican City State)</option
                        >
                        <option value="HN">Honduras</option>
                        <option value="HK">Hong Kong</option>
                        <option value="HU">Hungary</option>
                        <option value="IS">Iceland</option>
                        <option value="IN">India</option>
                        <option value="ID">Indonesia</option>
                        <option value="IR">Iran, Islamic Republic of</option>
                        <option value="IQ">Iraq</option>
                        <option value="IE">Ireland</option>
                        <option value="IM">Isle of Man</option>
                        <option value="IL">Israel</option>
                        <option value="IT">Italy</option>
                        <option value="JM">Jamaica</option>
                        <option value="JP">Japan</option>
                        <option value="JE">Jersey</option>
                        <option value="JO">Jordan</option>
                        <option value="KZ">Kazakhstan</option>
                        <option value="KE">Kenya</option>
                        <option value="KI">Kiribati</option>
                        <option value="KP"
                          >Korea, Democratic People's Republic of</option
                        >
                        <option value="KR">Korea, Republic of</option>
                        <option value="KW">Kuwait</option>
                        <option value="KG">Kyrgyzstan</option>
                        <option value="LA"
                          >Lao People's Democratic Republic</option
                        >
                        <option value="LV">Latvia</option>
                        <option value="LB">Lebanon</option>
                        <option value="LS">Lesotho</option>
                        <option value="LR">Liberia</option>
                        <option value="LY">Libya</option>
                        <option value="LI">Liechtenstein</option>
                        <option value="LT">Lithuania</option>
                        <option value="LU">Luxembourg</option>
                        <option value="MO">Macao</option>
                        <option value="MK"
                          >Macedonia, the former Yugoslav Republic of</option
                        >
                        <option value="MG">Madagascar</option>
                        <option value="MW">Malawi</option>
                        <option value="MY">Malaysia</option>
                        <option value="MV">Maldives</option>
                        <option value="ML">Mali</option>
                        <option value="MT">Malta</option>
                        <option value="MH">Marshall Islands</option>
                        <option value="MQ">Martinique</option>
                        <option value="MR">Mauritania</option>
                        <option value="MU">Mauritius</option>
                        <option value="YT">Mayotte</option>
                        <option value="MX">Mexico</option>
                        <option value="FM"
                          >Micronesia, Federated States of</option
                        >
                        <option value="MD">Moldova, Republic of</option>
                        <option value="MC">Monaco</option>
                        <option value="MN">Mongolia</option>
                        <option value="ME">Montenegro</option>
                        <option value="MS">Montserrat</option>
                        <option value="MA">Morocco</option>
                        <option value="MZ">Mozambique</option>
                        <option value="MM">Myanmar</option>
                        <option value="NA">Namibia</option>
                        <option value="NR">Nauru</option>
                        <option value="NP">Nepal</option>
                        <option value="NL">Netherlands</option>
                        <option value="NC">New Caledonia</option>
                        <option value="NZ">New Zealand</option>
                        <option value="NI">Nicaragua</option>
                        <option value="NE">Niger</option>
                        <option value="NG">Nigeria</option>
                        <option value="NU">Niue</option>
                        <option value="NF">Norfolk Island</option>
                        <option value="MP">Northern Mariana Islands</option>
                        <option value="NO">Norway</option>
                        <option value="OM">Oman</option>
                        <option value="PK">Pakistan</option>
                        <option value="PW">Palau</option>
                        <option value="PS"
                          >Palestinian Territory, Occupied</option
                        >
                        <option value="PA">Panama</option>
                        <option value="PG">Papua New Guinea</option>
                        <option value="PY">Paraguay</option>
                        <option value="PE">Peru</option>
                        <option value="PH">Philippines</option>
                        <option value="PN">Pitcairn</option>
                        <option value="PL">Poland</option>
                        <option value="PT">Portugal</option>
                        <option value="PR">Puerto Rico</option>
                        <option value="QA">Qatar</option>
                        <option value="RE">Réunion</option>
                        <option value="RO">Romania</option>
                        <option value="RU">Russian Federation</option>
                        <option value="RW">Rwanda</option>
                        <option value="BL">Saint Barthélemy</option>
                        <option value="SH"
                          >Saint Helena, Ascension and Tristan da Cunha</option
                        >
                        <option value="KN">Saint Kitts and Nevis</option>
                        <option value="LC">Saint Lucia</option>
                        <option value="MF">Saint Martin (French part)</option>
                        <option value="PM">Saint Pierre and Miquelon</option>
                        <option value="VC"
                          >Saint Vincent and the Grenadines</option
                        >
                        <option value="WS">Samoa</option>
                        <option value="SM">San Marino</option>
                        <option value="ST">Sao Tome and Principe</option>
                        <option value="SA">Saudi Arabia</option>
                        <option value="SN">Senegal</option>
                        <option value="RS">Serbia</option>
                        <option value="SC">Seychelles</option>
                        <option value="SL">Sierra Leone</option>
                        <option value="SG">Singapore</option>
                        <option value="SX">Sint Maarten (Dutch part)</option>
                        <option value="SK">Slovakia</option>
                        <option value="SI">Slovenia</option>
                        <option value="SB">Solomon Islands</option>
                        <option value="SO">Somalia</option>
                        <option value="ZA">South Africa</option>
                        <option value="GS"
                          >South Georgia and the South Sandwich Islands</option
                        >
                        <option value="SS">South Sudan</option>
                        <option value="ES">Spain</option>
                        <option value="LK">Sri Lanka</option>
                        <option value="SD">Sudan</option>
                        <option value="SR">Suriname</option>
                        <option value="SJ">Svalbard and Jan Mayen</option>
                        <option value="SZ">Swaziland</option>
                        <option value="SE">Sweden</option>
                        <option value="CH">Switzerland</option>
                        <option value="SY">Syrian Arab Republic</option>
                        <option value="TW">Taiwan, Province of China</option>
                        <option value="TJ">Tajikistan</option>
                        <option value="TZ">Tanzania, United Republic of</option>
                        <option value="TH">Thailand</option>
                        <option value="TL">Timor-Leste</option>
                        <option value="TG">Togo</option>
                        <option value="TK">Tokelau</option>
                        <option value="TO">Tonga</option>
                        <option value="TT">Trinidad and Tobago</option>
                        <option value="TN">Tunisia</option>
                        <option value="TR">Turkey</option>
                        <option value="TM">Turkmenistan</option>
                        <option value="TC">Turks and Caicos Islands</option>
                        <option value="TV">Tuvalu</option>
                        <option value="UG">Uganda</option>
                        <option value="UA">Ukraine</option>
                        <option value="AE">United Arab Emirates</option>
                        <option value="GB">United Kingdom</option>
                        <option value="US">United States</option>
                        <option value="UM"
                          >United States Minor Outlying Islands</option
                        >
                        <option value="UY">Uruguay</option>
                        <option value="UZ">Uzbekistan</option>
                        <option value="VU">Vanuatu</option>
                        <option value="VE"
                          >Venezuela, Bolivarian Republic of</option
                        >
                        <option value="VN">Viet Nam</option>
                        <option value="VG">Virgin Islands, British</option>
                        <option value="VI">Virgin Islands, U.S.</option>
                        <option value="WF">Wallis and Futuna</option>
                        <option value="EH">Western Sahara</option>
                        <option value="YE">Yemen</option>
                        <option value="ZM">Zambia</option>
                        <option value="ZW">Zimbabwe</option>
                      </select>
                    </div>
                  </div>
                </div> -->
              </div>
              <!--end: Wizard Step 1-->

              <!--begin: Wizard Step 2-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">Nhập thông tin vé</h4>
                <div class="row" style="position: relative">
                  <div class="col-md-12 col-lg-4">
                    <div class="form-group">
                      <label>Loại vé</label>
                      <b-form-radio-group
                        id="period_type"
                        v-model="ticketData.group_status"
                        :options="ticketType"
                        name="ticket-type"
                        size="lg"
                        @input="changeGroupStatus"
                      ></b-form-radio-group>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label>Số lượng vé</label>
                      <input
                        type="number"
                        class="form-control form-control-solid form-control-lg"
                        name="ticket-number"
                        :placeholder="12"
                        :min="1"
                        :max="!!ticketData.group_status ? 120 : 12"
                        v-model="ticketData.stock_amount"
                        :disabled="!!ticketData.group_status"
                        :state="validateStockAmount"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label>Tình trạng mở bán</label>
                      <b-form-checkbox
                        id="status"
                        v-model="ticketData.status_sale"
                        name="status_sale"
                        :value="1"
                        :unchecked-value="0"
                        size="lg"
                      >
                        Cho phép khách mua vé
                      </b-form-checkbox>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label>Bộ số</label>
                      <OtpInput
                        ref="otpInput"
                        input-classes="otp-input"
                        separator="-"
                        :num-inputs="ticketData.group_status == 1 ? 5 : 6"
                        :should-auto-focus="true"
                        :is-input-num="true"
                        @on-change="handleOnChange"
                        @on-complete="handleOnComplete"
                      />
                      <!-- <input
                    type="text"
                    class="form-control form-control-solid form-control-lg"
                    name="package"
                    placeholder="Package Details"
                    value="Complete Workstation (Monitor, Computer, Keyboard & Mouse)"
                  />
                  <span class="form-text text-muted"
                    >Please enter your Pakcage Details.</span
                  > -->
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-8">
                    <div class="form-group">
                      <label>Ảnh chụp</label>
                      <div class="d-flex file-group">
                        <div class="d-flex" style="flex-direction: column">
                          <b-form-file id="image" v-model="ticketData.image" placeholder="" browse-text="Chọn ảnh">
                            <template slot="file-name">
                              {{ "" }}
                            </template>
                          </b-form-file>
                          <span class="form-text text-muted" v-if="ticketData.image">Đã chọn ảnh {{ fileName }}</span>
                        </div>
                        <button class="btn btn-primary" @click="openCamera">Chụp ảnh</button>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="isCameraOpen"
                    v-show="!isLoading"
                    class="camera-box col-sm-12"
                    :class="{ flash: isShotPhoto }"
                  >
                    <video v-show="!isPhotoTaken" ref="camera" autoplay playsinline style="width: 100%"></video>
                    <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" :width="width" :height="height"></canvas>
                  </div>
                  <div v-if="isCameraOpen && !isLoading" class="camera-shoot">
                    <button type="button" class="button btn-success" @click="takePhoto">
                      <i class="flaticon2-photo-camera"></i>
                    </button>
                    <button type="button" class="button btn-danger ml-2" @click="closeCamera">
                      <i class="flaticon2-cancel-music"></i>
                    </button>
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 2-->

              <!--begin: Wizard Step 3-->
              <!-- <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  Select your Services
                </h4>
                <div class="form-group">
                  <label>Delivery Type</label>
                  <select
                    name="delivery"
                    class="form-control form-control-solid form-control-lg"
                  >
                    <option value="">Select a Service Type Option</option>
                    <option value="overnight" selected
                      >Overnight Delivery (within 48 hours)</option
                    >
                    <option value="express"
                      >Express Delivery (within 5 working days)</option
                    >
                    <option value="basic"
                      >Basic Delivery (within 5 - 10 working days)</option
                    >
                  </select>
                </div>
                <div class="form-group">
                  <label>Packaging Type</label>
                  <select
                    name="packaging"
                    class="form-control form-control-solid form-control-lg"
                  >
                    <option value="">Select a Packaging Type Option</option>
                    <option value="regular" selected>Regular Packaging</option>
                    <option value="oversized">Oversized Packaging</option>
                    <option value="fragile">Fragile Packaging</option>
                    <option value="frozen">Frozen Packaging</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Preferred Delivery Window</label>
                  <select
                    name="preferreddelivery"
                    class="form-control form-control-solid form-control-lg"
                  >
                    <option value="">Select a Preferred Delivery Option</option>
                    <option value="morning" selected
                      >Morning Delivery (8:00AM - 11:00AM)</option
                    >
                    <option value="afternoon"
                      >Afternoon Delivery (11:00AM - 3:00PM)</option
                    >
                    <option value="evening"
                      >Evening Delivery (3:00PM - 7:00PM)</option
                    >
                  </select>
                </div>
              </div> -->
              <!--end: Wizard Step 3-->

              <!--begin: Wizard Step 4-->

              <!--end: Wizard Step 4-->

              <!--begin: Wizard Step 5-->
              <!-- <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  Review your Details and Submit
                </h4>
                <div class="border-bottom mb-5 pb-5">
                  <div class="font-weight-bold mb-3">Current Address:</div>
                  <div class="line-height-md">
                    Address Line 1
                    <br />
                    Address Line 2 <br />
                    Melbourne 3000, VIC, Australia
                  </div>
                </div>
                <div class="border-bottom mb-5 pb-5">
                  <div class="font-weight-bold mb-3">Delivery Details:</div>
                  <div class="line-height-md">
                    Package: Complete Workstation (Monitor, Computer, Keyboard &
                    Mouse)
                    <br />
                    Weight: 25kg <br />
                    Dimensions: 110cm (w) x 90cm (h) x 150cm (L)
                  </div>
                </div>
                <div class="border-bottom mb-5 pb-5">
                  <div class="font-weight-bold mb-3">
                    Delivery Service Type:
                  </div>
                  <div class="line-height-md">
                    Overnight Delivery with Regular Packaging
                    <br />
                    Preferred Morning (8:00AM - 11:00AM) Delivery
                  </div>
                </div>
                <div class="mb-5">
                  <div class="font-weight-bold mb-3">Delivery Address:</div>
                  <div class="line-height-md">
                    Address Line 1
                    <br />
                    Address Line 2 <br />
                    Preston 3072, VIC, Australia
                  </div>
                </div>
              </div> -->
              <!--end: Wizard Step 5-->

              <!--begin: Wizard Actions -->
              <div class="d-flex justify-content-between border-top pt-10" style="flex-wrap: wrap">
                <b-button-group class="w-sm-100 mb-4">
                  <button
                    class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4 br-0"
                    data-wizard-type="action-prev"
                  >
                    Quay Lại
                  </button>
                  <button
                    class="btn btn-secondary font-weight-bold text-uppercase px-9 py-4 br-0"
                    @click="resetTicket"
                    data-wizard-type="action-prev"
                  >
                    Nhập Lại
                  </button>
                </b-button-group>
                <b-button-group class="w-sm-100 mb-4">
                  <button
                    class="btn btn-success font-weight-bold text-uppercase px-9 py-4 br-0"
                    data-wizard-type="action-submit"
                    v-if="currentStep == 2"
                    @click="saveTicket"
                  >
                    Lưu Và Tiếp Tục
                  </button>
                  <button
                    v-on:click="submit"
                    class="btn btn-primary font-weight-bold text-uppercase px-9 py-4 br-0"
                    data-wizard-type="action-submit"
                  >
                    Hoàn Tất
                  </button>
                  <button
                    class="btn btn-primary font-weight-bold text-uppercase px-9 py-4 br-0"
                    data-wizard-type="action-next"
                  >
                    Tiếp Theo
                  </button>
                </b-button-group>
              </div>
              <!--end: Wizard Actions -->
            </form>
            <!--end: Wizard Form-->
          </div>
        </div>
        <!--end: Wizard Body-->
      </div>
    </div>
    <!--end: Wizard-->
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-1.scss";
.otp-input {
  width: 30px;
  height: 30px;
  padding: 5px;
  margin: 0 5px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #f3f6f9;
  text-align: center;
  &.error {
    border: 1px solid red !important;
  }
  background-color: #f3f6f9;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.br-0 {
  border-radius: 0;
}
.w-sm-100 {
  width: auto !important;
}
.file-group {
  button {
    min-width: 100px;
    margin-left: 1rem;
    flex-direction: row;
  }
  align-items: flex-start;
}
.camera-shoot {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
  button {
    // background-color: #1BC5BD;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    i {
      width: 20px;
      color: #fff;
    }
  }
}
@media (max-width: 425px) {
  .w-sm-100 {
    width: 100% !important;
  }
  .file-group {
    flex-direction: column;
    button {
      margin-left: 0;
      margin-top: 1rem;
      max-width: 100px;
    }
  }
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
// import Swal from "sweetalert2";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import datePicker from "vue-bootstrap-datetimepicker";
import OtpInput from "@bachdgvn/vue-otp-input";
import axios from "axios";

const defaultPeriodData = {
  lottery_time: new Date(),
  channel: null,
};
const defaultTicketData = {
  image: null,
  stock_amount: 12,
  group_status: 0,
  status: 1,
  status_sale: 1,
};

export default {
  name: "StorageForm",
  components: { datePicker, OtpInput },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Kho" }, { title: "Nhập Kho" }]);

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v1", {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    // Validation before going to next page
    wizard.on("beforeNext", (wizardObj) => {
      // validate the form and use below function to stop the wizard's step
      if (!this.validateStep(wizardObj.currentStep)) {
        wizardObj.stop();
      }
      // console.log(wizardObj);
    });

    // Change event
    wizard.on("change", (wizardObj) => {
      this.currentStep = wizardObj.currentStep;
      this.goFirst = wizardObj.goFirst;
      // setTimeout(() => {
      //   KTUtil.scrollTop();
      // }, 500);
    });
    this.getPeriodChannels();
  },
  computed: {
    fileName() {
      const file = this.ticketData.image;
      // console.log(file);
      // console.log(file ? file.name : "")
      return file ? file.name : "";
    },
  },
  data() {
    return {
      periodData: { ...defaultPeriodData },
      ticketData: { ...defaultTicketData },
      currentStep: 1,
      isNewPeriod: false,
      dpOptions: {
        format: "DD/MM/YYYY",
        sideBySide: true,
        minDate: this.getStartDate(),
      },
      periodChannel: {
        0: [
          { value: "tiengiang", text: "Tiền Giang" },
          { value: "kiengiang", text: "Kiên Giang" },
          { value: "dalat", text: "Đà Lạt" },
        ],
        1: [
          { value: "tphcm", text: "TP Hồ Chí Minh" },
          { value: "dongthap", text: "Đồng Tháp" },
          { value: "camau", text: "Cà Mau" },
        ],
        2: [
          { value: "bentre", text: "Bên Tre" },
          { value: "vungtau", text: "Vũng Tàu" },
          { value: "baclieu", text: "Bạc Liêu" },
        ],
        3: [
          { value: "dongnai", text: "Đồng Nai" },
          { value: "cantho", text: "Cần Thơ" },
          { value: "soctrang", text: "Sóc Trăng" },
        ],
        4: [
          { value: "tayninh", text: "Tây Ninh" },
          { value: "angiang", text: "An Giang" },
          { value: "binhthuan", text: "Bình Thuận" },
        ],
        5: [
          { value: "vinhlong", text: "Vĩnh Long" },
          { value: "binhduong", text: "Bình Dương" },
          { value: "travinh", text: "Trà Vinh" },
        ],
        6: [
          { value: "tphcm", text: "TP Hồ Chí Minh" },
          { value: "longan", text: "Long An" },
          { value: "binhphuoc", text: "Bình Phước" },
          { value: "haugiang", text: "Hậu Giang" },
        ],
      },
      currentPeriodChannels: [],
      ticketType: [
        { value: 0, text: "Lẻ" },
        { value: 1, text: "Cặp nguyên" },
      ],
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      width: 200,
      height: 200,
      validateStockAmount: null,
      goFirst: () => {},
      // disabled: false
    };
  },
  methods: {
    resetTicket(e) {
      e.preventDefault();
      this.$refs.otpInput.clearInput();
      this.ticketData = { ...defaultTicketData };
      this.closeCamera(e);
    },
    changeGroupStatus() {
      this.ticketData.stock_amount = this.ticketData.group_status ? 120 : 12;
    },
    openCamera(e) {
      e.preventDefault();
      this.isCameraOpen = true;
      this.createCameraElement();
    },
    closeCamera(e) {
      e.preventDefault();
      this.isCameraOpen = false;
      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      this.stopCameraStream();
    },
    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
    },
    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: { facingMode: "environment" },
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch((error) => {
          this.isLoading = false;
          alert("May the browser didn't support or there is some errors: " + error);
        });
    },
    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;
      if (!this.isPhotoTaken) {
        return;
      }

      const context = this.$refs.canvas.getContext("2d");
      this.$refs.canvas.width = this.$refs.camera.clientWidth;
      this.$refs.canvas.height = this.$refs.camera.clientHeight;
      context.drawImage(this.$refs.camera, 0, 0, this.$refs.camera.clientWidth, this.$refs.camera.clientHeight);
      // let img = new Image();
      // img.src = this.$refs.canvas.toDataURL("image/png");
      this.ticketData = {
        ...this.ticketData,
        image: this.dataURLtoFile(this.$refs.canvas.toDataURL("image/png"), `${new Date().getTime()}.png`),
      };
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    getStartDate() {
      return new Date().setHours(0, 0, 0, 0);
    },
    getPeriodChannels() {
      this.currentPeriodChannels = [
        ...this.periodChannel[new Date(this.formatDateString(this.periodData.lottery_time)).getDay()],
      ];
    },
    async submit(e) {
      e.preventDefault();
      let saveTicket = await this.saveTicket(e);
      if (saveTicket) {
        this.resetTicket(e);
        this.periodData = { ...defaultPeriodData };
        this.goFirst();
      }
    },
    async saveTicket(e) {
      e.preventDefault();
      if (
        (this.ticketData.ticket_info.length != 6 && this.ticketData.group_status == 0) ||
        (this.ticketData.ticket_info.length != 5 && this.ticketData.group_status == 1) ||
        !this.ticketData.image
      ) {
        return false;
      }

      let body = new FormData();
      body.append("lottery_time", new Date(this.formatDateString(this.periodData.lottery_time)).toISOString());
      body.append("channel", this.periodData.channel);
      body.append("group_status", this.ticketData.group_status);
      if (this.ticketData.group_status) {
        body.append("stock_amount", 14);
        body.append("ticket_info", this.ticketData.ticket_info.slice(0, 5));
      } else {
        body.append("ticket_info", this.ticketData.ticket_info);
        body.append("stock_amount", this.ticketData.stock_amount);
      }
      body.append("status", 1); //this.ticketData.status);
      body.append("status_sale", this.ticketData.status_sale);
      body.append("image", this.ticketData.image);
      try {
        let response = await axios({
          method: "POST",
          url: `storages`,
          data: body,
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (response.status == 200) {
          this.$bvToast.toast("Nhập vé thành công", {
            title: `Thành công`,
            variant: "success",
            solid: true,
          });
          this.ticketData.ticket_info = "";
          this.$refs.otpInput.clearInput();
          this.ticketData.image = null;
          this.isPhotoTaken = false;
          return true;
        } else {
          this.$bvToast.toast("Thất bại", {
            title: `Có lỗi xảy ra, vui lòng thử lại`,
            variant: "danger",
            solid: true,
          });
          return false;
        }
      } catch (error) {
        // console.error(error);
        this.$bvToast.toast("Thất bại", {
          title: `Có lỗi xảy ra, vui lòng thử lại: ` + error,
          variant: "danger",
          solid: true,
        });
        return false;
      }
    },
    validateStep(step) {
      if (step == 1) {
        if (!this.periodData.channel || !this.periodData.lottery_time) {
          return false;
        }
      }
      return true;
    },
    handleOnComplete(value) {
      this.ticketData.ticket_info = value;
    },
    handleOnChange(value) {
      this.ticketData.ticket_info = value;
    },
  },
};
</script>
